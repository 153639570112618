* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: system-ui, sans-serif;
  color: black;
  background-color: white;
}

.user-selection-container {
  padding: 3rem;
  border-radius: 8px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.user-selection-container .header {
  margin: 1rem 0;
}

.user-selection-container .radio-input {
  display: flex;
  gap: 8px;
  align-items: center;
  margin-bottom: 1rem;
  padding-left: 1rem;
}

.user-selection-container .radio-input input {
  width: auto;
  height: 18px;
}

.user-selection-container .radio-input label {
  font-size: 18px;
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  a,
  button {
    font-size: 14px !important;
  }

  .heading-title.size-xl {
    font-size: 40px !important;
  }

  .user-selection-container .header {
    font-size: 18px;
  }

  .user-selection-container .radio-input label {
    font-size: 16px;
  }
}
